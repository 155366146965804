.popup-container.edit-page-popup {
    display: flex;
    flex-direction: column;
    min-width: 95vw;
    height: 90vh;
  }

  .popup-container.edit-page-popup .edit-page-popup-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .popup-container.edit-page-popup .edit-page-popup-header .act-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .popup-container.edit-page-popup
    .edit-page-popup-header
    .act-btns
    .button.primary {
    margin-left: 1rem;
    height: 2.5rem;
  }

  .popup-container.edit-page-popup .line-separator {
    margin-bottom: 1rem;
  }

  .popup-container.edit-page-popup .form-field {
    width: 50%;
  }

  .popup-container.edit-page-popup .mk-editor {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .popup-container.edit-page-popup .mk-editor .rc-md-editor {
    height: 100%;
    width:100%;
  }

  .popup-container.edit-page-popup
    .mk-editor
    .rc-md-editor
    .rc-md-navigation
    .button-wrap
    .button {
    margin-left: unset;
    margin-right: unset;
    padding: 0 0.9rem;
  }


.edit-page-popup .rc-md-editor,
.edit-page-popup .rc-md-navigation {
  border: 0;
  border-bottom: 0;
  background: inherit;
}

.edit-page-popup .editor-container .section-container.input  {
  border: 0;
  border-bottom: 0;
  background: inherit;
  color: #fff
}

.edit-page-popup .editor-container > .section {
  border-right: 1px solid var(--grey-palette-03);
}

.edit-page-popup .editor-container>.section:last-child {
  border-right: 0;
}
